import { UAParser } from 'ua-parser-js';

type DeviceType = 'pc' | 'handset';

// TODO: UserAgent廃止.
const ua = UAParser(navigator.userAgent);
export const UA_DEVICE_TYPE: DeviceType = ua.device.type === 'mobile' ? 'handset' : 'pc';

type DeviceRequiredProps = {
  name: string;
  owner: string;
  callees: string[];
  locationId: string;
};

type DeviceOptionalProps = object;

type DeviceProvidedProps = {
  deviceId: string;
  type: DeviceType;
  connectionId: string;
  organizationId: string;
  updatedAt: number;
  version: number;
};

export type Device = DeviceRequiredProps & DeviceOptionalProps & DeviceProvidedProps;
