import { Injectable, inject } from '@angular/core';
import { CanActivateFn, CanMatchFn, Router, UrlTree } from '@angular/router';
import { Observable, noop } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

import { AuthUsecase } from '../usecases/auth.usecase';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  canActivate(router: Router, authUsecase: AuthUsecase): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return authUsecase.authState$.pipe(
      map(authState => authState.status === 'signedIn'),
      tap(canActivate => (canActivate ? noop : router.navigate(['']))),
    );
  }

  canLoad(router: Router, authUsecase: AuthUsecase): Observable<boolean> | Promise<boolean> | boolean {
    return authUsecase.authState$.pipe(
      take(1),
      map(authState => authState.status === 'signedIn'),
      tap(canLoad => (canLoad ? noop : router.navigate(['']))),
    );
  }
}

export const canActivateAuth: CanActivateFn = () => {
  return inject(AuthGuard).canActivate(inject(Router), inject(AuthUsecase));
};

export const canLoadAuth: CanMatchFn = () => {
  return inject(AuthGuard).canLoad(inject(Router), inject(AuthUsecase));
};
