import { Injectable } from '@angular/core';
import { AsyncSubject, Observable } from 'rxjs';

import { OrganizerCreateParams, OrganizerUpdateParams, Organizers } from '../models/organizer.model';
import { DistinctSubject, recursiveQuery } from '../models/utility.model';
import { AuthUsecase } from '../usecases/auth.usecase';
import { OrganizerGateway } from '../usecases/organizer.gateway';
import { OrganizerUsecase } from '../usecases/organizer.usecase';

@Injectable()
export class OrganizerInteractor extends OrganizerUsecase {
  get organizers$(): Observable<Organizers> {
    return this._organizers;
  }

  private readonly _organizers = new DistinctSubject<Organizers>(new Organizers());

  constructor(
    private _authUsecase: AuthUsecase,
    private _organizerGateway: OrganizerGateway,
  ) {
    super();

    this._authUsecase.authState$.subscribe(({ status }) => {
      switch (status) {
        case 'signedIn':
          this.onSignIn();
          break;
        case 'signIn':
          this.onSignOut();
          break;
        default:
          // nop
          break;
      }
    });
  }

  createOrganizer(params: OrganizerCreateParams): Observable<never> {
    const result = new AsyncSubject<never>();
    this._organizerGateway.createOrganizer(params).subscribe({
      next: createdOrganizer => this._organizers.next(this._organizers.value.set(createdOrganizer)),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  updateOrganizer(organizerId: string, params: OrganizerUpdateParams): Observable<never> {
    const result = new AsyncSubject<never>();
    this._organizerGateway.updateOrganizer(organizerId, params).subscribe({
      next: updatedOrganizer => this._organizers.next(this._organizers.value.set(updatedOrganizer)),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  deleteOrganizer(organizerId: string): Observable<never> {
    const result = new AsyncSubject<never>();
    this._organizerGateway.deleteOrganizer(organizerId).subscribe({
      next: () => this._organizers.next(this._organizers.value.delete(organizerId)),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  reload(): void {
    this.onSignIn();
  }

  private onSignIn(): void {
    recursiveQuery(params => this._organizerGateway.listOrganizers(params), {}).subscribe(organizers => {
      this._organizers.next(new Organizers(organizers));
    });
  }

  private onSignOut(): void {
    this._organizers.next(new Organizers());
  }
}
